<template>
  <main>
    <div
      class="store-list row wrap"
      :style="{ margin: -Number(margin) + 'px' }"
    >
      <router-link
        :to="``"
        @click.native="test()"
        class="store-item"
        v-for="(item, index) in goodsList"
        :key="index"
        :style="{
          margin: margin + 'px',
          width: width + 'px',
          height: 360 + 'px',
          padding: ph + 'px ' + pv + 'px 0',
        }"
      >
        <div
          class="item-del"
          v-if="showDel"
          @click.prevent="$emit('delPro', item)"
        >
          <img src="@/assets/icon/icon-del1.png" alt="" class="default" />
          <img src="@/assets/icon/icon-del2.png" alt="" class="active" />
        </div>
        <div class="item-pro">
          <el-image :src="item.image" fit="cover" class="img" lazy></el-image>
        </div>
        <div class="item-imglist row mb10" v-if="prolists">
          <el-image
            :src="baseUrl + items.uri"
            fit="cover"
            class="img"
            v-for="(items, indexs) in item.goods_image"
            :key="indexs"
            lazy
          ></el-image>
        </div>
        <div
          class="item-title line2 normal"
          :style="{ fontSize: titleSize + 'px' }"
        >
          {{ item.name }}
        </div>

        <div class="item-price">
          <make-priceformat
            :price="item.min_price"
            class="mr10"
            :subscriptSize="priceSize"
            :firstSize="priceSize"
            :secondSize="priceSize"
          ></make-priceformat>
          <make-priceformat
            :price="item.market_price"
            :subscriptSize="oldpriceSize"
            :firstSize="oldpriceSize"
            :secondSize="oldpriceSize"
            color="#999999"
            v-if="oldpriceShow"
          ></make-priceformat>
        </div>
      </router-link>
    </div>
  </main>
</template>
<script>
import { h } from "vue";

export default {
  props: {
    margin: {
      type: [Number, String],
      default: 10,
    },
    width: {
      type: [Number, String],
      default: 284,
    },
    height: {
      type: [Number, String],
      default: 425,
    },
    // 垂直
    ph: {
      type: [Number, String],
      default: 10,
    },
    // 水平
    pv: {
      type: [Number, String],
      default: 40,
    },
    // 标题字体大小
    titleSize: {
      type: [Number, String],
      default: 14,
    },
    // 加入购物车
    join: {
      type: Boolean,
      default: true,
    },
    // 价格字体大小
    priceSize: {
      type: [Number, String],
      default: 16,
    },
    // 旧价格字体大小
    oldpriceSize: {
      type: [Number, String],
      default: 16,
    },
    // 显示旧价格
    oldpriceShow: {
      type: Boolean,
      default: true,
    },
    // 显示简介/细图
    prolists: {
      type: Boolean,
      default: false,
    },
    //商品列表
    goodsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 显示删除按钮
    showDel: {
      type: Boolean,
      default: false,
    },
    // 来源
    activityType: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      categoryList: [],
      categoryOne: 0, //一级分类
      categoryTwo: 0, //二级分类
      categoryThree: 0, //三级分类
      count: 0, //总条数
      goodsList: [], //商品列表
      page_no: 1,
      currentPage: 0, //当前页
      // 筛选条件
      screen: {
        default: true, //综合
        sales: "", //销量
        price: "", //价格
      },
      screenCurrent: 0,
      categoryId: "", //当前筛选ID
      hotData: [], //热门列表
      qualityData: [], //精品列表
      orderObject: {},
      keyword: "",
      hdsx: {
        id: 0,
        name: "全部",
      },
    };
  },
  methods: {
    selectGoods(id) {
      alert(id);
    },
    test() {
      alert(111);
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  .core {
    width: 1170px;
  }

  .hot {
    padding: 33px 0;

    .title {
      width: 46px;
      height: 150px;
      padding: 28px 14px 0;
    }

    .hot-list {
      width: 1113px;
      .hot-item {
        border: $-solid-border;
        width: 359px;
        height: 150px;
        margin-right: 18px;
        padding: 20px;
        align-items: normal;

        &:last-child {
          margin-right: 0;
        }

        .item-pro {
          width: 140px;
          height: 108px;
          flex-shrink: 0;
        }

        .item-text {
          align-items: normal;

          .text-title {
            line-height: 20px;
            height: 40px;
          }

          .cart-box {
            width: 90px;
            height: 32px;
            border: $-solid-cartborder;
            cursor: pointer;
          }
        }
      }
    }
  }

  .condition {
    padding-top: 20px;
    padding-bottom: 40px;

    .condition-box {
      padding: 0px 20px;
      border: $-solid-border;

      .condition-t {
        height: 54px;
        border-bottom: $-dashed-border;

        .title {
          margin-right: 35px;
        }

        .t-content {
          padding: 0 8px;
          height: 28px;
          border: $-solid-border;
        }
      }

      .condition-b {
        padding: 15px 0;
        align-items: flex-start;
        line-height: 1.8;

        .title {
          margin-right: 43px;
          flex-shrink: 0;
        }

        .brand-list {
          span {
            margin-right: 60px;
            cursor: pointer;

            &.active,
            &:hover {
              color: $-color-primary;
            }
          }
        }
      }
    }
  }
}
.store-list {
  padding-bottom: 170px;

  .store-item {
    background-color: $-color-white;
    position: relative;

    // margin-right: 21px;
    // margin-top: 21px;
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    .item-pro {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      margin-bottom: 10px;

      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .item-imglist {
      .img {
        margin-right: 4px;
        width: 37px;
        height: 37px;
        display: block;

        &:last-child {
          margin: 0;
        }
      }
    }

    .item-title {
      line-height: 1.5;
      margin-bottom: 5px;
    }

    .item-del {
      position: absolute;
      z-index: 2;
      width: 38px;
      height: 38px;
      right: 0;
      top: 0;

      &:hover {
        .default {
          display: none;
        }

        .active {
          display: block;
        }
      }

      .default {
        display: block;
      }

      .active {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .item-cart {
      .cart-btn {
        width: 115px;
        height: 35px;
        border: 1px solid $-color-cartc;

        .icon-cart {
          width: 17px;
          height: 17px;
        }
      }

      /deep/ .el-input-number {
        background: linear-gradient(0deg, #fafafa, #ededed);
        border: $-solid-border;
        box-sizing: border-box;
        width: 79px;
        height: 35px;
        line-height: 35px;
        color: $-color-black;

        .el-input-number__decrease,
        .el-input-number__increase {
          border: none;
          background-color: inherit;
          width: 27px;
          color: inherit;
          font-size: 12px;

          &.is-disabled {
            color: $-color-warn;
          }
        }

        .el-input__inner {
          height: 35px;
          border: none;
          background-color: inherit;
          padding: 0 27px;
          color: inherit;
        }
      }
    }
  }
}
</style>
